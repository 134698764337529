import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from './app/store';
import { createTheme, ThemeProvider } from '@mui/material';
import Login from './pages/Login';
const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
  palette:{
    primary:{
      main:'#166088',
      contrastText: '#fff',
    },
    secondary:{
      main:'#D6A319',
      contrastText: '#fff',
    }
  }
})
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}/>
        <Route path='/login' element={<Login/>}/>
        </Routes>
    
    </BrowserRouter>
    </ThemeProvider>
    </Provider>
  </React.StrictMode>
);


