import { createSlice } from "@reduxjs/toolkit";
export const menuSlice = createSlice(
    {
        name: 'menuState',
    initialState:{
        currentMenu:'all',
    },
    reducers:{
        changeMenu: (state,action)=>{            
            return action.payload
        },

    }
    })

    export const{changeMenu} = menuSlice.actions

    export default menuSlice.reducer