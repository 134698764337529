import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
    name: 'cart',
    initialState:{
        products:[],
        info:[],
        view:false
    },
    reducers:{
        addItemCart: (state,action)=>{            
            return {
                ...state,
                products: state.products.concat(action.payload)
            }
        },
        addQtyItem:(state,action)=>{
          let products = state.products;
          let data = products[action.payload];
          data = {
            ...data,
            qty: data.qty+ 1
          }
          products[action.payload] = data
        
        },
        minQtyItem:(state,action)=>{
          let products = state.products;
          let data = products[action.payload];
          data = {
            ...data,
            qty: data.qty - 1
          }
          if(data.qty === 0){
            const withoutExistProd= state.products.filter(p=> p !== products[action.payload]);
            return{
                ...state,
                products: withoutExistProd
            }
          }else{
              products[action.payload] = data
          }
        
        },
        delCart:(state,action)=>{
          return{
            ...state,
            products:[],
            info:[]
          }
        },
        delItem:(state,action)=>{

        },
        stateCart:(state,action)=>{
            return{
                ...state,
                view: action.payload
            }
        }

    }
})

export const {addItemCart,stateCart,addQtyItem,minQtyItem,delItem,delCart} = cartSlice.actions
export default cartSlice.reducer