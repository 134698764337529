import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./style.css"
import { Autoplay,EffectCoverflow, Pagination } from "swiper";
import SliderItem from "./SliderItem";
import { useSelector } from "react-redux";
import { baseUrl } from "../config/config";
import { Paper } from "@mui/material";
function SliderComponent(props) {
 
  const [offer,setOffer] = useState([])
  const info = useSelector(state=>state.data)
 useEffect( ()=>{
    const prod = info.products;
    const offers = prod.filter(p=>p.offer.length > 0)
    setOffer(offers)
  },[info])

  return (
    <>
   { offer &&
     <Swiper
     effect={"coverflow"}
     grabCursor={true}
     centeredSlides={true}
     slidesPerView={"auto"}
     coverflowEffect={{
       rotate: 50,
       stretch: 0,
       depth: 100,
       modifier: 1,
       slideShadows: true,
     }}
     autoplay={{
       delay: 3000,
       disableOnInteraction: false,
     }}
     pagination={{
       clickable: true,
     }}
     modules={[Autoplay,EffectCoverflow, Pagination]}
     className="mySwiper"
   >
      {
       info.client &&
       <SwiperSlide>
       <Paper style={{height:300}}>
       <img  src={baseUrl+'storage/'+info.client.logo.url} />
       </Paper>
     </SwiperSlide>
      }
      

     {offer.map((values,key)=>
    <SwiperSlide key={key}>
      <SliderItem {...props} data={values}/>
     </SwiperSlide>    
    )}
   </Swiper>
  
   }
  </>
  )
}

export default SliderComponent