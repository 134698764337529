import { useSelector } from "react-redux";

function FooterComponent() {
  const clientData = useSelector(state=>state.data.client);
  return (
   <>
    {
      clientData ?
      <div style={{padding:5, color:'#fff'}}>
      <p>Encuentranos en: <span>{clientData.direction}</span></p>
      <p>©2022</p>
    </div>:'cargando'
  }
   </>
  )
}

export default FooterComponent