
import { Chip } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenu } from '../features/menuControl/menuSlice';
function ChipGroup() {
    const dispatch = useDispatch();
    const currentMenu = useSelector(state=>state.currentMenu);
    const allData = useSelector(state=>state.data);
    const handleClick=(dat)=>{
        dispatch(changeMenu({currentMenu:dat}))
      }
  return (
    <div className='' style={{ marginTop: 30,marginBottom: 30, maxWidth:'100%'}}>
      <Grid2 container spacing={1}>
      <Grid2 >
            <Chip label={'Todos'}   size='small' color={ currentMenu.currentMenu === "all" ? 'secondary':'primary' }  onClick={()=>handleClick('all')} />
        </Grid2>
      {
          allData.category.map((dat)=>
          <Grid2 key={dat.id}>
            <Chip label={dat.category} style={{textTransform:'capitalize'}} size='small' color={ currentMenu.currentMenu === dat.id ? 'secondary':'primary' } onClick={()=>handleClick(dat.id)} />
        </Grid2>
          )        
    }
  
      </Grid2>
     </div>
  )
}

export default ChipGroup