import axios from 'axios'
import { baseUrl } from '../config/config'

const urlBase = baseUrl+'api'




export const getAll = async () =>{
    const res = await axios.get(urlBase+'/all/poker')
    return res.data;
}

