import { Paper, Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { baseUrl } from '../config/config';

const SliderItem = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {data} = props;
 
  return (
    <Paper style={{height:300}} onClick={()=>props.handleClickOpen(data)}>
        <div>
          <div style={{position:'absolute',background:'#F0C808',width:'30%', top:'40%'}}>
            <Typography variant="subtitle2" color='white' align='center'>Oferta</Typography>
            <Typography variant="subtitle2" color='white' align='center'> Bs.-
            { data.offer[0].final_price}
              </Typography>            
          </div>
          <div style={{position:'absolute',background:'#F0C808',width:'100%', bottom:0}}>
            <Typography variant="subtitle1" color='white' align='center'>{data.name}</Typography>                   
          </div>
          <img style={{ width:'auto', height: 300, display:'block',marginLeft:'auto',marginRight:'auto' }} src={baseUrl+'storage/'+data.images[0].url} />
        </div>
      
    </Paper>
  )
}

export default SliderItem