import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Swiper, SwiperSlide } from "swiper/react";
import { baseUrl } from "../config/config";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { addItemCart, stateCart } from "../features/cart/cartSlice";
import { green, orange } from "@mui/material/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";


function ItemDetail({ handleClose, open, data }) {
  const [cart, setCart] = useState({
    id: "",
    size: "",
    color: "",
    qty: "",
    product: "",
  });
  const [size, setSize] = useState(false);
  const [color, setColor] = useState(false);
  const [incart, setIncart] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const cartList = useSelector((state) => state.cart);

  const handleChange = (event) => {
    setCart({
      ...cart,
      [event.target.name]: event.target.value,
    });
  };
  const handleClick = () => {
    if (cart.size !== "" && cart.color !== "") {
      setColor(false);
      setSize(false);
      dispatch(addItemCart(cart));
    } else {
      if (cart.size == "") {
        setSize(true);
      } else {
        setSize(false);
      }
      if (cart.color == "") {
        setColor(true);
      } else {
        setColor(false);
      }
    }
  };
  useEffect(() => {
    setCart({
      ...cart,
      product: data,
      qty: 1,
      id: data.id,
    });
  }, []);
  useEffect(() => {
    const carts = cartList.products;

    if (carts.length > 0) {
      const productInCart = carts.filter((p) => {
        return (
          p.product === cart.product &&
          p.color === cart.color &&
          p.size === cart.size
        );
      });
      setIncart(false);
      if (productInCart.length > 0) {
        setIncart(true);
      }
    }else{
      setIncart(false);
    }
  }, [cart, cartList]);
  const addQty = () => {
    const qtyCart = cart.qty;
    setCart({
      ...cart,
      qty: qtyCart + 1,
    });
  };
  const subtractQty = () => {
    const qtyCart = cart.qty;
    if (qtyCart > 1) {
      setCart({
        ...cart,
        qty: qtyCart - 1,
      });
    }
  };
  const handleClickCart = () => {
    handleClose()
    dispatch(stateCart(true));
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {data.name}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {data.images.map((img, key) => (
            <SwiperSlide key={key}>
              <img src={baseUrl + "storage/" + img.url} />
            </SwiperSlide>
          ))}
        </Swiper>
        <DialogTitle id="responsive-dialog-title">{data.name}</DialogTitle>
        <DialogTitle>
        
          {data.offer[0] ? (
            <Typography align="center">
              Bs.- {data.offer[0].final_price}{" "}
              <span style={{ textDecoration: "line-through" }}>
                Bs.- {data.price}
              </span>
            </Typography>
          ) : (
            <Typography align="center">Bs.- {data.price} </Typography>
          )}
        </DialogTitle>
        <DialogContentText>{data.description}</DialogContentText>
        <Grid2 container spacing={2}>
          <Grid2 item xs={6}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
              <InputLabel id="demo-customized-select-label">Tamaño</InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={cart.size}
                name="size"
                error={size}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Seleccionar</em>
                </MenuItem>
                {data.size.map((size, key) => (
                  <MenuItem value={size} key={key}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 item xs={6}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
              <InputLabel id="demo-customized-select-label">Color</InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={cart.color}
                name="color"
                error={color}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Seleccionar</em>
                </MenuItem>
                {data.colors.map((color, key) => (
                  <MenuItem value={color} key={key}>
                    {color}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                background: green[500],
                borderRadius: "15px",
                width: "50%",
              }}
            >
              <Button style={{ color: "white" }} onClick={subtractQty}>
                <RemoveRoundedIcon />
              </Button>
              <Grid2
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  background: "white",
                  margin: 2,
                  padding: "0 15px 0 15px ",
                }}
              >
                <Typography variant="h6">{cart.qty}</Typography>
              </Grid2>
              <Button style={{ color: "white" }} onClick={addQty}>
                <AddRoundedIcon />
              </Button>
            </div>
          </Grid2>
        </Grid2>
      </DialogContent>

      <Grid2 container style={{paddingBottom: 20}}>
        <Grid2
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {incart ? (
            <Fab
              color={orange[500]}
              aria-label="card"
              variant="extended"
              onClick={handleClickCart}
            >
              <Typography variant="boddy2">Agregado al carrito</Typography>
            </Fab>
          ) : (
            <Fab
              color="primary"
              aria-label="card"
              variant="extended"
              onClick={handleClick}
            >
              <Typography variant="boddy2">
                Agregar al carrito | Bs.-
                {data.offer[0]
                  ? data.price - (data.offer[0].offer / 100) * data.price
                  : data.price}
              </Typography>
            </Fab>
          )}
        </Grid2>
      </Grid2>
    </Dialog>
  );
}

export default ItemDetail;
