import {  Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { baseUrl } from '../config/config';


function ItemsComponent({handleClickOpen,data}) {
  
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <>
    <Card style={{height:'100%'}}>
        <CardActionArea onClick={()=>handleClickOpen(data)} >
          <CardMedia 
          component="img" 
          height="240"
          image= {baseUrl+"storage/"+data.images[0].url}
          alt=""
          />
         
           <CardContent>
            <Typography variant='subtitle2'>{data.name}</Typography>
            {data.offer[0] ? 
            
            <Typography variant='boddy2' >Bs.- { data.offer[0].final_price} <span style={{textDecoration:'line-through'}}>Bs.- {data.price}</span></Typography>       
            :<Typography variant='boddy2'> Bs.- {data.price} </Typography>}
            
           </CardContent>
        </CardActionArea>
    </Card>
    
  </>
  )
}

export default ItemsComponent