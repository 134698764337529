import { createSlice } from "@reduxjs/toolkit";


export const dataSlice = createSlice({
    name: 'allData',
    initialState:{
        category:[],
        products:[],
    },
    reducers:{
        addData: (state,action)=>{            
            return action.payload
        },

    }
})

export const {addData} = dataSlice.actions

export default dataSlice.reducer