import axios from 'axios'
import { baseUrl } from '../config/config'

const urlBase = baseUrl+'api'

export const setOrder = async (data) =>{
    
    const res = await axios.post(urlBase+'/new-orden/poker',data)
    return res.data;
}

