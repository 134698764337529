import { configureStore } from "@reduxjs/toolkit";
import dataSlice from "../features/alldata/dataSlice";
import  cartSlice  from "../features/cart/cartSlice";
import menuSlice from "../features/menuControl/menuSlice";

const store = configureStore({
    reducer:{
        data: dataSlice,
        currentMenu: menuSlice,
        cart: cartSlice
    }
})

export default store;