import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import Grid2 from "@mui/material/Unstable_Grid2";
import { baseUrl } from "../config/config";
import { green } from "@mui/material/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";


import "react-phone-input-2/lib/material.css";
import { addQtyItem, minQtyItem, stateCart } from "../features/cart/cartSlice";
import CartForm from "./CartForm";
function CartComponent() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const productList = cart.products;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  
  const [data, setData] = useState({
    name: "",
    email: "",
    direction: "",
    aditional: "",
  });
  
  const handleClose = () => {
    dispatch(stateCart(false))
  };
  const handleClick = () => {};
  const subtractQty = (e) => {
    dispatch(minQtyItem(e));
  };
  const addQty = (e) => {
    dispatch(addQtyItem(e));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={cart.view}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Tu carrito de Compras 
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
      {productList.map((item, key) => (
        <ListItem
          key={key}
          alignItems="flex-start"
          sx={{ my: 2, boxShadow: 3 }}
        >
          <ListItemAvatar>
            <img
              style={{ height: 60 }}
              src={baseUrl + "storage/" + item.product.images[0].url}
            />
          </ListItemAvatar>
          <div style={{ width: "100%" }}>
            <ListItemText primary={item.product.name} />
            <Fragment>
              <Typography>Color : {item.color}</Typography>
              <Typography>Talla : {item.size}</Typography>
              <Grid2 container>
                <Grid2
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      background: green[500],
                      borderRadius: "15px",
                    }}
                  >
                    <Button
                      style={{ color: "white" }}
                      onClick={() => subtractQty(key)}
                    >
                      <RemoveRoundedIcon />
                    </Button>
                    <Grid2
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        background: "white",
                        margin: 2,
                        padding: "0 15px 0 15px ",
                      }}
                    >
                      <Typography variant="h6">{item.qty}</Typography>
                    </Grid2>
                    <Button
                      style={{ color: "white" }}
                      onClick={() => addQty(key)}
                    >
                      <AddRoundedIcon />
                    </Button>
                  </div>
                </Grid2>
              </Grid2>
            </Fragment>
          </div>
          {item.product.offer[0] ? (
            <Typography align="center">
              Bs.-
              {item.product.offer[0].final_price}{" "}
              <span style={{ textDecoration: "line-through" }}>
                .Bs.-{item.product.price}
              </span>
            </Typography>
          ) : (
            <Typography align="center">
              Bs.-{item.product.price}{" "}
            </Typography>
          )}
        </ListItem>
      ))}
    </List>
        <CartForm/>
      </DialogContent>
    </Dialog>
  );
}

export default CartComponent;
