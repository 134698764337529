import { useFormik } from "formik";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import { Box, Button, Fab, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOrder } from "../services/setOrder";
import { delCart } from "../features/cart/cartSlice";
import wpp from'../img/wpp.svg'
const validationSchema = yup.object({
  name: yup.string("Igrese su nombre").required("Igrese su nombre"),
  email: yup
    .string("Igrese su correo electronico")
    .email("Igrese un correo electronico valido")
    .required("Igrese su correo electronico"),
  direction: yup.string("Igrese su dirección").required("Igrese su dirección"),
  aditional: yup.string("Igrese información adicional de su pedido"),
});
function CartForm() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const productList = cart.products;
  const [phone, setPhone] = useState("");
  const [total, setTotal] = useState(0);
  const [response,setResponse] = useState(false)
  const [infoResponse, setInfoResponse] = useState({});
  const allData = useSelector(state=>state.data);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      direction: "",
      aditional: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

        let data = Object.assign(values,{phone: phone, cart:productList})        
      setOrder(data).then(res=>{
        if(!res.error){
           dispatch(delCart())
           setResponse(true)
           setInfoResponse(res.data)
           console.log(res.data)
        }else{
            console.log('algo mal')
        }
      })
    },
  });
  useEffect(() => {
    if (productList.length > 0) {
      const total = productList.reduce(
        (sum, value) =>
          value.product.offer[0]
            ? sum +
              (value.qty *
                (value.product.offer[0].final_price))
            : sum + (value.qty * value.product.price),
        0
      );
      setTotal(total);
    }
  }, [productList]);

  const onChangePhone = (value) => {
    setPhone(value);
  };
  if(!response){

    return (
     <div>
      {
        !formik.isSubmitting &&
        <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="h6" align="right">
            Total : Bs.- {total}{" "}
          </Typography>
          <Typography variant="h6">Datos personales.</Typography>
  
          <TextField
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
            fullWidth
            sx={{ my: 1 }}
            id="filled-basic"
            label="Nombres y apellidos"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined"
          />
          <TextField
            onChange={formik.handleChange}
            value={formik.values.email}
            name="email"
            fullWidth
            sx={{ my: 1 }}
            id="filled-basic"
            label="Email"
            type="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant="outlined"
          />
          <TextField
            onChange={formik.handleChange}
            value={formik.values.direction}
            error={formik.touched.direction && Boolean(formik.errors.direction)}
            helperText={formik.touched.direction && formik.errors.direction}
            name="direction"
            fullWidth
            sx={{ my: 1 }}
            id="filled-basic"
            label="Dirección"
            variant="outlined"
          />
  
          <PhoneInput
            country={"bo"}
            regions={"south-america"}
            value={phone}
            onChange={onChangePhone}
            label="Nro de Celular"
            sx={{ my: 1 }}
            inputClass=""
            helperText="asd"
          />
          <TextField
            onChange={formik.handleChange}
            value={formik.values.aditional}
            error={formik.touched.aditional && Boolean(formik.errors.aditional)}
            helperText={formik.touched.aditional && formik.errors.aditional}
            name="aditional"
            fullWidth
            sx={{ my: 1 }}
            id="filled-basic"
            label="Información Adicional"
            variant="outlined"
          />
  
          <Typography align="center" sx={{ py: 3 }}>
            Una vez realizado el pedido, nos pondremos en contacto con usted
          </Typography>
        </Box>
        <Grid2 container style={{ paddingBottom: 20 }}>
          <Grid2
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Fab
              color="primary"
              aria-label="card"
              variant="extended"
              type="submit"
            >
              <Typography variant="boddy2">Realizar pedido</Typography>
            </Fab>
          </Grid2>
        </Grid2>
      </form>
      }
     </div>
    );
  }else{
    return (
      <div>
        <Typography variant="h6" align="center">{infoResponse.name}</Typography>
        <Typography align="center">Su pedido fue enviado correctamente en breve nos pondremos en contacto al {infoResponse.phone}</Typography>
        <Typography align="center">Su código de pedido es:</Typography>
        <Typography variant="h4" align="center">{infoResponse.code}</Typography>
        <Typography>Si tus datos no son los correctos, o requiere alguna información puedes contactarte con el siguiente enlace.</Typography>
        <Button variant='contained' color="success" fullWidth href={'https://api.whatsapp.com/send?phone='+allData.client.wpp+'&text=El código de mi pedido es '+ infoResponse.code} target='_blank'>
        <img src={wpp} style={{height:35, marginRight:8}} />
           envianos un mensaje
        </Button>
      </div>
    )
  }
}

export default CartForm;
