import React, { useEffect } from 'react';

import Grid2 from '@mui/material/Unstable_Grid2';
import ItemsComponent from "./component/ItemsComponent";
import SliderComponent from "./component/SliderComponent";
import Container from '@mui/material/Container';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Fab from '@mui/material/Fab';
import { Box, Link,Typography} from '@mui/material';
import FooterComponent from './component/FooterComponent';
import ItemDetail from './component/ItemDetail';
import { useDispatch, useSelector } from 'react-redux';
import { addData } from './features/alldata/dataSlice';
import { getAll } from './services/getAllData';

import ChipGroup from './component/ChipGroup';
import CartComponent from './component/CartComponent';
import { stateCart } from './features/cart/cartSlice';
import wpp from'./img/wpp.svg'
import tiktok from'./img/tiktok.svg'
import face from'./img/face.svg'
import tw from'./img/tw.svg'
import insta from'./img/insta.svg'
import youTube from'./img/youTube.svg'
function App() {
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [client, setClient] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState([]);

  const currentMenu = useSelector(state=>state.currentMenu);
  const allData = useSelector(state=>state.data);
  const cartData = useSelector(state=>state.cart.products)
  
  const dispatch = useDispatch();

  const openCart =()=>{
    dispatch(stateCart(true))
  }

  useEffect(()=>{
    if(currentMenu.currentMenu === 'all'){
      setProducts(allData.products);
    }else{
      const filterProduct = allData.products.filter(({category_id})=> category_id === currentMenu.currentMenu);    
      setProducts(filterProduct);

    }
  },[currentMenu,allData])

  useEffect(()=>{
    getAll().then(res=>{
      dispatch(addData(res))
    })
    setOpen(false)
    setLoading(false)
  },[])
 
  const handleClickModal=(data)=>{
    setData(data)
    setOpen(true)
  }
  const handleCloseModal=()=>{
    setOpen(false)
  }
  const handleAddCart=()=>{

  }

  return(
   <>
    {loading ? <div>cargando</div>
    : <>
     {allData.client ?
    <div style={{paddingTop:'20px',paddingBottom: '20px',color:'#fff', fontSize:'1rem'}}>
      <Typography align='center' variant='h3' component='h1' style={{textTransform:'uppercase'}}> {allData.client.name}</Typography>
      <div style={{width:'auto', position:'absolute', left: 0 , transform: 'translate(calc(50vw - 50%)'}}>
      {
      allData.client.wpp &&<Link href={'https://api.whatsapp.com/send?phone='+allData.client.wpp+'&text=me%20interesa%20productos%20de%20tu%20catalogo'} target='_blank' style={{padding:5}}>
        <img src={wpp} style={{height:35}} />
      </Link>
      }
      {
        allData.client.other && <Link href={allData.client.other} style={{padding:5}} target='_blank'>
        <img src={face} style={{height:35}} />
      </Link>}
      {
        allData.client.tiktok && <Link href={allData.client.tiktok} style={{padding:5}} target='_blank'>
        <img src={tiktok} style={{height:35}} />
      </Link>}
      {
      allData.client.instagram &&
        <Link href={allData.client.instagram} style={{padding:5}} target='_blank'>
        <img src={insta} style={{height:35}} />
      </Link>}
      {
        allData.client.youTube && <Link href={allData.client.youTube} style={{padding:5}} target='_blank'>
        <img src={youTube} style={{height:35}} />
      </Link>}

      </div>
    </div>:''
    }

    <div style={{background:"#fff",borderRadius:"35px 0px"}}>
      
        {allData?
      <div style={{paddingTop:20, paddingBottom: 20,  }}>
      <SliderComponent handleClickOpen={handleClickModal}/>
      </div>
      : <>cargando...</>
      }
      <Container>

      <ChipGroup/>
      
      <Grid2 container columns={{xs:2, sm:4, md:8}} spacing={2}>
      {
        products.map((data)=>
        <Grid2 xs={1} sm={2} key={data.id}>          
          <ItemsComponent data={data}  handleClickOpen={handleClickModal} />
        </Grid2>        
        )
      }
          
      </Grid2>
      </Container>
    </div>
      <FooterComponent/>
      {
        cartData.length > 0 && <Box style={{width:'auto', position:'fixed', bottom:10, left: 0 , transform: 'translate(calc(50vw - 50%)'}}>
        <Fab color="primary" aria-label="card" variant='extended' onClick={openCart}>
           <ShoppingCartIcon/>
           Tu Orden 
         </Fab>
        </Box>
      }
    
    {
      data ?<ItemDetail open={open} handleClose={handleCloseModal} data={data} handleAddCart={handleAddCart}/>
     : <></>
     }
      </>
    }
    <CartComponent/>
    </>)
  
}

export default App;
